import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

import {
  API_URL,
  sendAuthenticatedGetRequest,
  sendAuthenticatedPostRequest,
} from '../../../services/http';

import Layout from '../Layout';
import InputField from '../../ui/inputField';
import DropDownField from '../../ui/dropdown';
import FilePicker from '../../ui/filePicker';
import {
  ADD_REPORT_FAILED,
  ADD_REPORT_SUCCESS,
  TOAST_STATUS,
  TYPE_OF_REPORT_DROPDOWN,
} from '../../../Constants';

interface ReportData {
  name: string;
  type: string;
  file: string;
}

const ReportSchema = Yup.object({
  name: Yup.string().required('Name is required').max(50, 'Name must be at most 50 characters'),
  typeOfReport: Yup.string().required('Type of report is required'),
  pdf: Yup.mixed().test(
    'fileSize',
    'Max file size is 100MB',
    value => !value || value.size / 1024 / 1024 < 101
  ),
});

const ElectionAddOrEditReport: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const EMPTY = '';
  const [initialValues, setInitialValues] = useState({
    name: EMPTY,
    typeOfReport: EMPTY,
    pdf: EMPTY,
  });

  const queryParams = new URLSearchParams(location.search);
  const reportId = queryParams.get('id');

  useEffect(() => {
    if (reportId) {
      setLoading(true);
      sendAuthenticatedGetRequest(`${API_URL}/election-reports/${reportId}`)
        .then(response => {
          const data = response.data as ReportData;
          setInitialValues({
            name: data.name,
            typeOfReport: data.type,
            pdf: data.file,
          });
          setLoading(false);
        })
        .catch(() => {
          history.replace('/election-reports');
        });
    }
  }, [reportId, history]);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const reportData: any = new FormData();

    reportData.append('name', values.name);
    reportData.append('type', values.typeOfReport);
    if (values.pdf instanceof File) reportData.append('file', values.pdf);

    const url = reportId
      ? `${API_URL}/election-reports/${reportId}` // Update if ID exists
      : `${API_URL}/election-reports`; // Create if no ID

    sendAuthenticatedPostRequest(url, reportData)
      .then(() => {
        setLoading(false);
        history.push({
          pathname: '/election-reports',
          state: {
            toastStatus: TOAST_STATUS,
            toastMessage: reportId ? 'Report updated successfully' : ADD_REPORT_SUCCESS,
            severity: 'success',
          },
        });
      })
      .catch(() => {
        setLoading(false);
        history.push({
          pathname: '/election-reports',
          state: {
            toastStatus: TOAST_STATUS,
            toastMessage: ADD_REPORT_FAILED,
            severity: 'error',
          },
        });
      });
  };

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">
            {reportId ? 'Edit Report' : 'Add Report'}
          </span>

          {loading && !reportId ? (
            <div className="flex justify-center mt-10">
              <CircularProgress />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={ReportSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange, setFieldValue }) => (
                <Form className="w-full">
                  <div className="border-b-2 border-gray-300 mb-10">
                    <div className="my-8">
                      <InputField
                        title="Name"
                        isRequired={true}
                        value={values.name}
                        formikError={errors.name}
                        formikTouch={touched.name}
                        onChangeHandler={handleChange('name')}
                        containerClassNames="mt-5"
                      />
                      <DropDownField
                        title="Type of Report"
                        isRequired={true}
                        value={values.typeOfReport}
                        formikError={errors.typeOfReport}
                        formikTouch={touched.typeOfReport}
                        data={TYPE_OF_REPORT_DROPDOWN}
                        onChangeHandler={handleChange('typeOfReport')}
                      />
                      <FilePicker
                        title="Report"
                        isRequired={!reportId}
                        formikError={errors.pdf}
                        formikTouch={touched.pdf}
                        fileName={values.pdf}
                        acceptFileType="application/pdf"
                        onChangeHandler={(e: any) => {
                          setFieldValue('pdf', e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end mt-10">
                    <button
                      className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base"
                      onClick={() =>
                        history.replace({
                          pathname: '/election-reports',
                        })
                      }
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ElectionAddOrEditReport;
