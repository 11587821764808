import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';

import NotFound from './components/screens/404';
import Login from './components/templates/login/LoginForm';
import Dashboard from './components/screens/Dashboard';
import Incidents from './components/screens/Incidents';
import IncidentForm from './components/screens/Incidents/Form';
import Reports from './components/screens/Reports';
import AddOrEditReport from './components/screens/Reports/AddOrEditReport';
import Profile from './components/screens/Profile';
import AddUserRole from './components/screens/Users/AddUserRole';
import Users from './components/screens/Users';
import KeyObservations from './components/screens/KeyObservations';
import AddObservation from './components/screens/KeyObservations/AddObservation';
import AddBulkReport from './components/screens/Incidents/AddBulkReport';
import Election from './components/screens/Election';

import { USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION } from './Constants';
import EditUser from './components/screens/Users/EditUser';
import ElectionIncidentForm from './components/screens/Election/ElectionIncidentForm';
import ElectionDashboard from './components/screens/Election/ElectionDashboard';
import ElectionReports from './components/screens/Election/ElectionReports';
import ElectionAddOrEditReport from './components/screens/ElectionReports/ElectionAddOrEditReport';
import ResetPassword from './components/templates/resetPassword/ResetPassword';
import ForgotPassword from './components/templates/forgotPassword/ForgotPassword';

function App() {
  return (
    <div>
      <Router>
        <Route exact path="/login" component={Login} />
        <Switch>
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <ProtectedRoute
            exact
            path="/"
            component={Dashboard}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
          <ProtectedRoute
            exact
            path="/incidents"
            component={Incidents}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/incidents/add"
            component={IncidentForm}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/incidents/:incidentId"
            component={IncidentForm}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/reports"
            component={Reports}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
          <ProtectedRoute
            exact
            path="/reports/add"
            component={AddOrEditReport}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/profile"
            component={Profile}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
          <ProtectedRoute exact path="/users" component={Users} roles={[USER_ROLE_ADMIN]} />
          <ProtectedRoute
            exact
            path="/users/add"
            component={AddUserRole}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/users/edit/:userId"
            component={EditUser}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/keyObservations"
            component={KeyObservations}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/keyObservations/add"
            component={AddObservation}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/incident/addBulkReport"
            component={AddBulkReport}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/election-incidents"
            component={Election}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/election-incidents/add"
            component={ElectionIncidentForm}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/election-incidents/:electionIncidentId"
            component={ElectionIncidentForm}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/election-dashboard"
            component={ElectionDashboard}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
          <ProtectedRoute
            exact
            path="/election-reports"
            component={ElectionReports}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />

          <ProtectedRoute
            exact
            path="/election-reports/add"
            component={ElectionAddOrEditReport}
            roles={[USER_ROLE_ADMIN]}
          />

          <ProtectedRoute
            exact
            path="*"
            component={NotFound}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
