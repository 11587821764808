import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import { API_URL, sendGetRequest, sendPostRequest } from '../../../services/http/index';
import InputField from '../../ui/inputField';
import Link from '@mui/material/Link';

const ResetPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*/=?])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [validToken, setValidToken] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    // Get the token from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    // Verify the token
    const verifyToken = async () => {
      if (token) {
        try {
          await sendGetRequest(`${API_URL}/auth/verify-token?token=${token}`);
          setValidToken(true);
        } catch (err) {
          setShowError(true);
          setErrorMessage('Invalid or expired token. Please try again.');
          history.push('/login');
        }
      } else {
        history.push('/login');
      }
    };
    verifyToken();
  }, [history]);

  const handlePasswordReset = async (values: { newPassword: string }) => {
    setLoading(true);
    const data = { password: values.newPassword };

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    await sendPostRequest(
      `${API_URL}/auth/reset-password?token=${token}`,
      JSON.stringify({ ...data })
    )
      .then(() => {
        setLoading(false);
        setShowSuccess(true);
        setTimeout(() => {
          // Redirect to login after successful password reset
          history.push('/');
          window.location.reload();
        }, 3000);
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage(err.response.data.message);
        setShowError(true);
      });
  };

  return (
    <div
      className="reset-password bg-repeat-round"
      style={{ backgroundImage: `url(${'/bg_login.jpg'})` }}
    >
      <Formik
        initialValues={{ newPassword: '', confirmPassword: '' }}
        validationSchema={ResetPasswordSchema}
        onSubmit={handlePasswordReset}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <div className="bg-white p-8 rounded-md">
            <img
              className="object-contain w-52 mx-auto mb-14"
              src="/logo.png"
              alt="hashtag generation logo"
            />
            <span className="font-semibold text-gray-700 text-2xl">RESET PASSWORD</span>
            <div className="my-8">
              <InputField
                isHorizontal={false}
                title="New Password"
                type="password"
                value={values.newPassword}
                placeholder="Enter your new password"
                containerClassNames="w-80 mt-5"
                onChangeHandler={handleChange('newPassword')}
                formikError={errors.newPassword}
                formikTouch={touched.newPassword}
              />
              <InputField
                isHorizontal={false}
                title="Confirm Password"
                type="password"
                value={values.confirmPassword}
                placeholder="Confirm your new password"
                containerClassNames="w-80 mt-5"
                onChangeHandler={handleChange('confirmPassword')}
                formikError={errors.confirmPassword}
                formikTouch={touched.confirmPassword}
              />
              {showError && <div className="w-80 text-red-500 text-xs mt-1">{errorMessage}</div>}
              {showSuccess && (
                <div className="w-80 text-green-500 text-xs mt-1">
                  Password reset. Redirecting to the login screen!
                </div>
              )}
            </div>
            <button
              className="bg-indigo-500 focus:outline-none w-24 h-10 mx-auto rounded-lg text-white text-base flex justify-center items-center"
              onClick={() => handleSubmit()}
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress color="inherit" size={20} /> : 'Reset'}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
