import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import InputField from '../../ui/inputField';
import { Link, useHistory } from 'react-router-dom';
import {
  STATUS_ALL,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  USER_ROLE_ADMIN,
} from '../../../Constants';
import useUserData from '../../../services/auth/useUserData';
import DataTable from '../../ui/dataTable';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import DropDownField from '../../ui/dropdown';
import { FaFileExport, FaFilter, FaRedo } from 'react-icons/fa';
import axios from 'axios';
import {
  API_URL,
  sendAuthenticatedGetRequest,
  sendAuthenticatedGetRequestWithResponseType,
} from '../../../services/http';
import Toastr from '../../ui/toastr';

const columnData = [
  { field: 'incidentId', headerName: 'ID', filterable: false, sortable: false },
  { field: 'link', headerName: 'Link', flex: 2, filterable: false, sortable: false },
  { field: 'stage', headerName: 'Stage', flex: 2, filterable: false, sortable: false },
  { field: 'source', headerName: 'Source', flex: 1, filterable: false, sortable: false },
  { field: 'language', headerName: 'Language', flex: 1, filterable: false, sortable: false },
  { field: 'postType', headerName: 'Post Type', flex: 1, filterable: false, sortable: false },
  { field: 'reportedBy', headerName: 'Recorded By', flex: 1, filterable: false, sortable: false },
  { field: 'incidentStatus', headerName: 'Status', flex: 1, filterable: false, sortable: false },
];

const FILTER_STORAGE_KEY = 'electionIncidentFilters';

const Index: React.FC = () => {
  const history = useHistory();
  const { userData } = useUserData();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabType, setTabType] = useState<string>(STATUS_ALL);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [urlParams, setUrlParams] = useState<any>({ sortOrder: 'DESC', sortKey: 'updatedDate' });
  const [showAlert, setShowAlert] = React.useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [statusAllCount, setStatusAllCount] = useState(-1);
  const [statusPendingCount, setStatusPendingCount] = useState(-1);
  const [statusApprovedCount, setStatusApprovedCount] = useState(-1);
  const [statusRejectedCount, setStatusRejectedCount] = useState(-1);

  const counts = useSelector((state: RootState) => state.electionIncidents.incidentCounts);

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [stagesList, setStagesList] = useState<any[]>([]);
  const [languageList, setLanguageList] = useState<any[]>([]);
  const [typeOfPostList, setTypeOfPostList] = useState<any[]>([]);

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [error, setError] = useState('');

  const [searchText, setSearchText] = useState<string>();
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [selectedStages, setSelectedStages] = useState<any>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<any>([]);
  const [selectedPostTypes, setSelectedPostTypes] = useState<any>([]);
  const [idFilter, setIdFilter] = useState<string>('');
  const [recordedByFilter, setRecordedByFilter] = useState<string>('');
  const [linkFilter, setLinkFilter] = useState<string>('');

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const getDropDownData = async () => {
    try {
      const categoryReq = sendAuthenticatedGetRequest(`${API_URL}/electionCategories`);
      const sourceReq = sendAuthenticatedGetRequest(`${API_URL}/electionStages`);
      const languageReq = sendAuthenticatedGetRequest(`${API_URL}/languages`);
      const typeOfPostReq = sendAuthenticatedGetRequest(`${API_URL}/socialMediaPlatformContents`);

      await axios.all([categoryReq, sourceReq, languageReq, typeOfPostReq]).then(
        axios.spread(function (
          categoryReq: any,
          sourceReq: any,
          languageReq: any,
          typeOfContentReq: any
        ) {
          setCategoryList(categoryReq.data.electionCategories);
          setStagesList(sourceReq.data.electionStages);
          setLanguageList(languageReq.data.languages);
          setTypeOfPostList(typeOfContentReq.data.socialMediaPlatformContents);
        })
      );
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  useEffect(() => {
    if (counts !== undefined) {
      setStatusAllCount(counts.ALL);
      setStatusPendingCount(counts.PENDING);
      setStatusApprovedCount(counts.APPROVED);
      setStatusRejectedCount(counts.REJECTED);
    }
  }, [counts]);

  const rowClickHandler = (row: any) => {
    history.push('/election-incidents/' + row.id);
  };

  useEffect(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      setSelectedCategories(filters.selectedCategories || []);
      setSelectedStages(filters.selectedStages || []);
      setSelectedLanguages(filters.selectedLanguages || []);
      setSelectedPostTypes(filters.selectedPostTypes || []);
      setIdFilter(filters.idFilter || '');
      setRecordedByFilter(filters.recordedByFilter || '');
      setLinkFilter(filters.linkFilter || '');
      setSearchText(filters.searchText || '');
      setTabType(filters.tabType || STATUS_ALL);
      setUrlParams(filters.urlParams || { sortOrder: 'DESC', sortKey: 'updatedDate' });
    }
  }, []);

  const saveFiltersToLocalStorage = (filters: any) => {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filters));
  };

  const applyFilter = (selectedStatus: string) => {
    const params: any = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (selectedStatus !== STATUS_ALL) {
      params.status = selectedStatus;
    }
    if (selectedCategories.length) params.categories = selectedCategories;
    if (selectedStages.length) params.stages = selectedStages;
    if (selectedLanguages.length) params.languages = selectedLanguages;
    if (selectedPostTypes.length) params.postTypes = selectedPostTypes;
    if (recordedByFilter) params.recordedBy = recordedByFilter;
    if (idFilter) params.id = idFilter;
    if (linkFilter) params.link = linkFilter;
    if (searchText) params.searchKey = searchText;

    setUrlParams(params);
    saveFiltersToLocalStorage({
      selectedCategories,
      selectedStages,
      selectedLanguages,
      selectedPostTypes,
      idFilter,
      recordedByFilter,
      linkFilter,
      searchText,
      tabType: selectedStatus,
      urlParams: params,
    });
  };

  const resetFilter = () => {
    setSelectedCategories([]);
    setSelectedStages([]);
    setSelectedLanguages([]);
    setSelectedPostTypes([]);
    setRecordedByFilter('');
    setIdFilter('');
    setLinkFilter('');
    setSearchText('');
    setTabType(STATUS_ALL);

    const params = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    setUrlParams(params);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  };

  const tabChangeHandler = (index: number, selectedStatus: string) => {
    setTabIndex(index);
    setTabType(selectedStatus);
    setSelectedRows([]);
    applyFilter(selectedStatus);
  };

  const handleStartDateChange = (e: { target: { value: any } }) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (endDate && newStartDate > endDate) {
      setError('End date must be later than start date.');
    } else {
      setError('');
    }
  };

  const handleEndDateChange = (e: { target: { value: any } }) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    if (startDate && newEndDate < startDate) {
      setError('End date must be later than start date.');
    } else {
      setError('');
    }
  };

  const validateDates = (startDate: string, endDate: string): string | null => {
    if (!startDate || !endDate) {
      return 'Start date and end date are required.';
    }
    if (new Date(startDate) > new Date(endDate)) {
      return 'End date must be later than start date.';
    }
    return null;
  };

  const initiateDownload = (responseData: Blob, startDate: string, endDate: string) => {
    const url = window.URL.createObjectURL(responseData);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `election_incidents_data_${startDate}_to_${endDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = async () => {
    setError('');

    // Validate dates
    const dateValidationError = validateDates(startDate, endDate);
    if (dateValidationError) {
      setError(dateValidationError);
      return;
    }

    // Show success alert
    setShowAlert(true);
    setToastMessage('Your download will start shortly');
    setSeverity('success');

    const params = { ...urlParams, startDate: startDate, endDate: endDate };
    console.log(params);

    try {
      // Send request to fetch the export file
      const response = await sendAuthenticatedGetRequestWithResponseType<Blob>(
        `${API_URL}/election-incident/export`,
        params,
        'blob'
      );

      // Initiate file download
      initiateDownload(response.data, startDate, endDate);
    } catch (error) {
      setError('Failed to export data.');
    }
  };

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Election Incidents</span>
          <div className="flex justify-between items-center my-8">
            <div className="grid grid-auto grid-cols-2 lg:grid-cols-4 gap-x-4 w-full">
              <DropDownField
                title="Category"
                data={categoryList}
                value={selectedCategories}
                onChangeHandler={e => setSelectedCategories(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Stage"
                data={stagesList} // Replace with appropriate data source if needed
                value={selectedStages}
                onChangeHandler={e => setSelectedStages(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Language"
                data={languageList}
                value={selectedLanguages}
                onChangeHandler={e => setSelectedLanguages(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Post Type"
                data={typeOfPostList}
                value={selectedPostTypes}
                onChangeHandler={e => setSelectedPostTypes(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <InputField
                isHorizontal={false}
                title="ID"
                placeholder="Enter ID"
                value={idFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setIdFilter(e.target.value)}
              />
              <InputField
                isHorizontal={false}
                title="Recorded By"
                placeholder="Enter user name"
                value={recordedByFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setRecordedByFilter(e.target.value)}
              />
              <InputField
                isHorizontal={false}
                title="Link"
                placeholder="Enter link"
                value={linkFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setLinkFilter(e.target.value)}
              />
              <InputField
                isHorizontal={false}
                title="Search Text"
                placeholder="Search Text"
                value={searchText}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center justify-end w-full">
            <button
              className="flex items-center justify-between bg-indigo-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
              type="button"
              onClick={() => applyFilter(tabType)}
            >
              Apply Filter
              <FaFilter className="text-lg pl-1" />
            </button>
            <button
              className="flex items-center justify-between bg-gray-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
              type="button"
              onClick={resetFilter}
            >
              Reset
              <FaRedo className="text-lg pl-1" />
            </button>
          </div>
          <div className="flex justify-between items-center my-8">
            <div>
              <Link to="/election-incidents/add">
                <button
                  type="button"
                  className="bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                >
                  Add Incident
                </button>
              </Link>
            </div>
          </div>
          <div className="flex mb-5">
            <button
              onClick={() => tabChangeHandler(0, STATUS_ALL)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 0 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              All {statusAllCount !== -1 && `(` + statusAllCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(1, STATUS_PENDING)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 1 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              Pending {statusPendingCount !== -1 && `(` + statusPendingCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(2, STATUS_APPROVED)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 2 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              Approved {statusApprovedCount !== -1 && `(` + statusApprovedCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(3, STATUS_REJECTED)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 3 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              Rejected {statusRejectedCount !== -1 && `(` + statusRejectedCount + `)`}
            </button>
          </div>
          {tabType === STATUS_ALL && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
              dataFor="ELECTION_INCIDENT"
            />
          )}
          {tabType === STATUS_PENDING && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
              dataFor="ELECTION_INCIDENT"
            />
          )}
          {tabType === STATUS_APPROVED && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              hasCheckbox={true}
              onRowClickHandler={rowClickHandler}
              onRowSelectionHandler={(ids: any) => setSelectedRows(ids)}
              dataFor="ELECTION_INCIDENT"
            />
          )}
          {tabType === STATUS_REJECTED && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
              dataFor="ELECTION_INCIDENT"
            />
          )}
          <Toastr
            autoHideDuration={6000}
            onClose={handleClose}
            open={showAlert}
            severity={severity && severity}
            message={toastMessage}
          />
        </div>
        {userData.role === USER_ROLE_ADMIN && (
          <div className="flex justify-between items-start my-8">
            <div className="grid grid-auto grid-cols-2 lg:grid-cols-4 gap-x-4 w-full">
              <InputField
                isHorizontal={false}
                title="Start Date"
                placeholder="Select start date"
                type="date"
                value={startDate}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={handleStartDateChange}
              />
              <InputField
                isHorizontal={false}
                title="End Date"
                placeholder="Select end date"
                type="date"
                value={endDate}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={handleEndDateChange}
              />
              <div className="flex items-center justify-start">
                <button
                  className="flex items-center justify-between bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
                  type="button"
                  onClick={handleExport}
                >
                  Export
                  <FaFileExport className="text-lg pl-1" />
                </button>
              </div>
              {error && <div className="col-span-2 text-red-500 text-sm mt-2">{error}</div>}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Index;
