import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { API_URL, sendPostRequest } from '../../../services/http/index';
import InputField from '../../ui/inputField';
import Link from '@mui/material/Link';

const ForgotPasswordSchema = Yup.object({
  email: Yup.string().trim().email('Email must be valid').required('Email address is required'),
});

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  return (
    <div
      className="forgot-password bg-repeat-round"
      style={{ backgroundImage: `url(${'/bg_login.jpg'})` }}
    >
      <Formik
        initialValues={{ email: '' }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={async values => {
          setLoading(true);
          const data = { email: values.email };

          await sendPostRequest(`${API_URL}/auth/forgot-password`, JSON.stringify(data))
            .then(() => {
              setLoading(false);
              setShowSuccess(true);
              setShowError(false);
            })
            .catch(err => {
              setLoading(false);
              setErrorMessage(err.response.data.message);
              setShowError(true);
              setShowSuccess(false);
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <div className="bg-white p-8 rounded-md">
            <img
              className="object-contain w-52 mx-auto mb-14"
              src="/logo.png"
              alt="hashtag generation logo"
            />
            <span className="font-semibold text-gray-700 text-2xl">FORGOT PASSWORD</span>
            <div className="my-8">
              <InputField
                isHorizontal={false}
                title="Email"
                type="email"
                value={values.email}
                placeholder="Enter your email"
                containerClassNames="w-80 mt-5"
                onChangeHandler={handleChange('email')}
                formikError={errors.email}
                formikTouch={touched.email}
              />
              {showError && <div className="w-80 text-red-500 text-xs mt-1">{errorMessage}</div>}
              {showSuccess && (
                <div className="w-80 text-green-500 text-xs mt-1">
                  Password reset link has been sent!
                </div>
              )}
              <div className="my-1">
                <Link className="mt-1 text-xs float-right" href="/login" underline="hover">
                  {'Back to Login'}
                </Link>
              </div>
            </div>
            <button
              className="bg-indigo-500 focus:outline-none w-24 h-10 mx-auto rounded-lg text-white text-base flex justify-center items-center"
              onClick={() => handleSubmit()}
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress color="inherit" size={20} /> : 'Send Link'}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
